<script setup lang="ts">
import {
	BaseTextField,
	useAsyncState,
	BaseButton,
	getTextFieldBinds,
	BaseFetchWrapper,
} from '@legacy-studio/library';
import { useForm } from 'vee-validate';
import { editEmailSchema } from '../../../core/validation';
import {
	setEmailEdit,
	setEmailNotEdit,
	useChangeEmail,
} from '../../../core/application';
import type { EditLoginForm } from '../../../types';
import { useIsEmailEdit } from '../../../infrastructure/store';
import EditForm from '../Form.vue';
import {
	UiVerificationLabel,
} from '@/src/shared/ui';
import { useTraderProfile } from '@/src/features/profile/infrastructure/store/';

const user = useTraderProfile();
const isEmailEdit = useIsEmailEdit();

const status = computed(() => user.value?.emailVerified ? 'verified' : false);

const verificationEmailText = computed(() => {
	let verificationText = 'Не подтверждена';

	if (status.value) {
		verificationText = 'Подтверждена';
	}

	return `${user.value.email}: ${verificationText} `;
});

const { defineField, handleSubmit } = useForm<EditLoginForm>({
	validationSchema: editEmailSchema,
	initialValues: {
		email: user.value.email || '',
	},
});

const [newEmail, newEmailProps] = defineField('email', getTextFieldBinds);

const { execute, isLoading } = useAsyncState(useChangeEmail());

const onSubmit = handleSubmit(execute);
</script>

<template>
	<BaseFetchWrapper
		v-if="user"
		class="relative flex flex-col gap-4"
		:pending="isLoading"
	>
		<div class="flex flex-col justify-between gap-4">
			<p class="text-base font-semibold text-white">
				Электронная почта
			</p>
			<div class="flex items-center justify-between gap-[30px]">
				<UiVerificationLabel
					:status="status"
					:title="verificationEmailText"
					subtitle="Вы можете сменить электронную почту, к которой привязан ваш счет."
				/>

				<BaseButton
					v-if="!isEmailEdit"
					color="alternative-dark"
					size="xs"
					button-text="Изменить"
					outline
					class="w-full md:w-auto"
					@click="setEmailEdit"
				/>
			</div>
		</div>

		<EditForm
			v-if="isEmailEdit"
			@submit="onSubmit"
			@cancel="setEmailNotEdit"
		>
			<BaseTextField
				v-model="newEmail"
				v-bind="newEmailProps"
				placeholder="Введите новый email"
				label="Новый email"
			/>
		</EditForm>
	</BaseFetchWrapper>
</template>
