import { apiMethods } from '../../infrastructure/api';
import { saveTraderProfile } from '../../infrastructure/store';

export const useGetTraderProfile = () => {
	const getTraderProfile = apiMethods.useGetTraderProfile();

	return async () => {
		const result = await getTraderProfile();
		if (result.isRight()) {
			saveTraderProfile(result.value);
		}
		else {
			throw result.value;
		}
	};
};
