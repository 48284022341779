<script setup lang="ts">
import {
	BaseTextField,
	BaseSelect,
	useAsyncState,
	BaseButton,
	getTextFieldBinds,
	BaseFetchWrapper,
	getSelectBinds,
	BaseDatePicker,
} from '@legacy-studio/library';
import { useForm } from 'vee-validate';
import { editPersonalSchema } from '../../../core/validation';
import {
	setPersonalEdit,
	setPersonalNotEdit,
	useUpdateTraderPersonal,
} from '../../../core/application';
import type { EditPersonalTraderForm } from '../../../types';

import { useIsPersonalEdit } from '../../../infrastructure/store';
import EditForm from '../Form.vue';
import FindCityField from './FindCityField.vue';
import {
	useSharedDataCountries,
	useSharedDataGetCountries,
} from '~/src/features/shared-data';
import { useGenders } from '~/src/shared/lib';
import {
	UiVerificationLabel,
} from '@/src/shared/ui';
import { useTraderProfile } from '@/src/features/profile/infrastructure/store/';

const user = useTraderProfile();
const isPersonalEdit = useIsPersonalEdit();

const canBeEdited = computed(() => {
	if (!user.value?.verification) {
		return true;
	}
	return user.value?.verification?.status?.code === 'rejected';
});

const isConfirmed = computed(() => {
	if (!user.value) {
		return false;
	}

	return (
		!!user.value?.firstName
		&& !!user.value?.lastName
		&& !!user.value?.gender
		&& !!user.value?.birthDate
		&& !!user.value?.phone
		&& !!user.value?.country?.code
		&& !!user.value?.city
		&& !!user.value?.address
		&& !!user.value?.citizenship
	);
});

const status = computed(() => isConfirmed.value ? 'verified' : false);
const verificationText = computed(() => `Данные: ${isConfirmed.value ? 'Предоставлены' : 'Не предоставлены'}`);

useSharedDataGetCountries()();
const genders = useGenders();
const countries = useSharedDataCountries();
const countriesRu = computed(() => countries.value.map((item) => {
	return {
		id: item.id,
		name: item.name.ru,
		code: item.code,
	};
}));

const { defineField, handleSubmit, setValues } = useForm<EditPersonalTraderForm>({
	validationSchema: editPersonalSchema,
	initialValues: user.value
		? {
				firstName: user.value.firstName,
				lastName: user.value.lastName,
				gender: user.value.gender,
				birthday: [user.value.birthDate, null] as any, // TOOD: ?
				phone: user.value.phone ?? '',
				country: (user.value.country as any)?.code, // TOOD: ?
				citizenship: user.value.citizenship || '',
				city: user.value.city,
				address: user.value.address ?? '',
			}
		: {},
});

const [firstName, firstNameProps] = defineField('firstName', getTextFieldBinds);
const [lastName, lastNameProps] = defineField('lastName', getTextFieldBinds);
const [_email, _emailProps] = defineField('email', getTextFieldBinds);
const [gender, genderProps] = defineField('gender', getSelectBinds);
const [phone, phoneProps] = defineField('phone', getTextFieldBinds);
const [birthday, birthdayProps] = defineField('birthday', getTextFieldBinds);
const [country, countryProps] = defineField('country', getSelectBinds);
const [_city, cityProps] = defineField('city', getTextFieldBinds);
const [citizenship, citizenshipProps] = defineField('citizenship', getTextFieldBinds);
const [address, addressProps] = defineField('address', getTextFieldBinds);

watch(country, () => setValues({ city: '' }));

const { execute, isLoading } = useAsyncState(useUpdateTraderPersonal());

const onSubmit = handleSubmit(async (values) => {
	const countryId = countriesRu.value.find(item => item.code === values.country).id;
	values.country = countryId;
	await execute(values);
	setPersonalNotEdit();
});
</script>

<template>
	<BaseFetchWrapper
		class="relative flex flex-col gap-4"
		:pending="isLoading"
	>
		<div class="flex flex-col justify-between gap-4">
			<p class="text-base font-semibold text-white">
				Персональные данные
			</p>
			<div class="flex items-center justify-between gap-[30px]">
				<UiVerificationLabel
					:status="status"
					:title="verificationText"
					subtitle="Ваша информация будет храниться в строгой конфиденциальности."
				/>

				<BaseButton
					v-if="!isPersonalEdit && canBeEdited"
					color="alternative-dark"
					size="xs"
					button-text="Изменить"
					outline
					class="w-full md:w-auto"
					@click="setPersonalEdit"
				/>
			</div>
		</div>

		<EditForm
			v-if="isPersonalEdit"
			@submit="onSubmit"
			@cancel="setPersonalNotEdit"
		>
			<BaseTextField
				v-model="firstName"
				v-bind="firstNameProps"
				placeholder="Введите имя по паспорту"
				label="Имя"
			/>

			<BaseTextField
				v-model="lastName"
				v-bind="lastNameProps"
				placeholder="Введите фамилию по паспорту"
				label="Фамилия"
			/>

			<BaseSelect
				v-model="gender"
				v-bind="genderProps"
				placeholder="Выберите пол"
				label="Пол"
				:items="genders"
			/>

			<BaseDatePicker
				v-model="birthday"
				v-bind="birthdayProps"
				mode="single"
				placeholder="Выберите дату"
				label="Дата рождения"
				single
			/>

			<BaseTextField
				v-model="phone"
				v-bind="phoneProps"
				placeholder="Телефон"
				label="Номер телефона"
			/>

			<BaseSelect
				v-model="country"
				v-bind="countryProps"
				placeholder="Выберите страну"
				label="Страна проживания"
				:items="countriesRu"
			/>

			<BaseTextField
				v-model="citizenship"
				v-bind="citizenshipProps"
				placeholder="Введите гражданство по паспорту"
				label="Гражданство"
			/>

			<FindCityField
				:initial="cityProps.modelValue"
				:reset-form="cityProps.modelValue === ''"
				:country-code="countryProps.modelValue"
				@select="(value) => setValues({ city: value.city })"
			/>

			<BaseTextField
				v-model="address"
				v-bind="addressProps"
				placeholder="Введите адрес проживания"
				label="Адрес"
			/>
		</EditForm>
	</BaseFetchWrapper>
</template>
