<script setup lang="ts">
import { BaseDropdown, BaseTextField, useAsyncState, BaseSpinner } from '@legacy-studio/library';
import { onClickOutside, useDebounceFn, useToggle } from '@vueuse/core';
import { useSharedDataCities } from '@/src/features/shared-data';
import type { Citi, Cities } from '@/src/features/shared-data/types';

const props = defineProps<{
	initial?: string;
	countryCode?: string;
	resetForm: boolean;
}>();

const emit = defineEmits<{
	select: [item: string];
}>();

const text = ref(props.initial || '');
const resetField = computed(() => props.resetForm);
const items = ref<Cities>([]);

const [isOpen, toggleIsOpen] = useToggle();

const select = (item: Citi) => {
	text.value = item.address;
	toggleIsOpen(false);
	emit('select', item);
};

const getCitiInfo = useSharedDataCities();

const { execute, isLoading } = useAsyncState(async (value: string) => {
	const result = await getCitiInfo(props.countryCode, value);
	items.value = result;
});

const onChange = useDebounceFn(execute, 400);

const target = ref<HTMLElement | null>(null);
watch(text, onChange);
watch(resetField, (newValue: boolean) => {
	if (newValue) {
		text.value = '';
	}
});

onClickOutside(target, () => {
	toggleIsOpen(false);
});
</script>

<template>
	<div
		ref="target"
		class="relative"
	>
		<BaseTextField
			v-model="text"
			label="Город"
			placeholder="Введите город"
			@focus="toggleIsOpen(true)"
		/>

		<BaseDropdown
			v-if="isOpen"
			class="absolute left-0 right-0 top-[calc(100%+5px)] z-10 flex max-h-[311px] flex-col gap-3 overflow-y-auto p-4"
		>
			<h4 class="text-sm font-semibold text-white">
				Выберите вариант или продолжите ввод
			</h4>

			<div v-if="isLoading">
				<BaseSpinner
					class="mx-auto"
					size="sm"
				/>
			</div>

			<div
				v-else
				class="flex flex-col gap-3"
			>
				<div
					v-if="!items.length"
					class="text-sm text-gray-300"
				>
					ничего не найдено
				</div>

				<button
					v-for="item in items"
					:key="item.city"
					type="button"
					class="flex flex-col text-left text-sm font-normal"
					@click="select(item)"
				>
					<span class="text-primary-400">
						{{ item.city }}
					</span>

					<span class="text-gray-400">
						{{ item.region }}
					</span>
				</button>
			</div>
		</BaseDropdown>
	</div>
</template>
