<script setup lang="ts">
import {
	BaseDropdown,
	formatAmount,
	BaseIcon,
	useAsyncState,
	BindPopover,
} from '@legacy-studio/library';
import { useToggle } from '@vueuse/core';
import {
	useChangeActiveInvoice,
	useGetInvoices,
	useRefreshDemoInvoice,
} from '../core/application';
import { useActiveInvoice, useInvoices } from '../infrastructure/store';
import { TariffsBadge } from '../../tariffs';
import type { InvoiceId } from '../types';
import { useIsUserAdmin, useUserRequired } from '~/src/features/user';
import { UiIcon } from '~/src/shared/ui';

const changeActiveInvoice = useChangeActiveInvoice();
const getInvoices = useGetInvoices();
const refreshDemoInvoice = useRefreshDemoInvoice();

const invoices = useInvoices();
const activeInvoice = useActiveInvoice();
const user = useUserRequired();

const [isOpen, toggle] = useToggle();

const iconActiveInvoice = computed(() => {
	if (!activeInvoice.value) {
		return '';
	}

	return `filled--invoices--${activeInvoice.value.type.code}` as const;
});

const onSelect = (id: InvoiceId) => {
	toggle(false);
	changeActiveInvoice(id);
};

const { execute: refreshBalance, isLoading }
  = useAsyncState(refreshDemoInvoice);

getInvoices();

const activeInvoiceBalance = computed(() => {
	const maybeBalance = activeInvoice.value?.balance;
	return maybeBalance ? formatAmount(maybeBalance) : '';
});

const isUserAdmin = useIsUserAdmin();
</script>

<template>
	<div
		v-if="!isUserAdmin"
		class="flex gap-2 items-center justify-center"
	>
		<span
			v-if="activeInvoiceBalance"
			class="text-base md:text-xl font-bold flex gap-1"
		>
			<span class="text-white">
				{{ activeInvoiceBalance }}
			</span>
		</span>

		<div
			class="hidden md:flex text-gray-500 uppercase p-1.5 bg-gray-600 rounded-lg items-center justify-center"
		>
			<UiIcon
				class="text-[20px]"
				:name="iconActiveInvoice"
			/>
		</div>

		<button
			type="button"
			class="p-1.5 flex items-center justify-center"
			@click="toggle()"
		>
			<BaseIcon
				name="base--fill--chevron-down"
				class="text-[20px] text-white"
				:class="{
					'rotate-180': isOpen,
				}"
			/>
		</button>

		<BindPopover
			v-if="isOpen"
			:top="32"
			:right="10"
		>
			<BaseDropdown class="absolute right-0 z-10 py-1 top-[calc(100%+3px)] p-3 flex flex-col gap-3 ring-1 ring-gray-600">
				<template
					v-for="(invoice, idx) in invoices"
					:key="invoice"
				>
					<button
						type="button"
						class="p-3 flex flex-col gap-1 rounded-lg hover:ring-1 ring-inset hover:ring-gray-600 hover:bg-gray-600/[0.32]"
						:class="{
							'ring-1 ring-gray-600 bg-gray-600/[0.32]':
								activeInvoice?.id === invoice.id,
						}"
						@click="onSelect(invoice.id)"
					>
						<div class="flex gap-2 items-center">
							<div
								class="w-4 h-4 rounded-full"
								:class="{
									'ring-[3.5px] ring-inset ring-primary-300 bg-gray-800':
										activeInvoice?.id === invoice.id,
									'ring-[0.5px] ring-gray-500 bg-gray-600':
										activeInvoice?.id !== invoice.id,
								}"
							/>

							<span class="leading-none text-sm font-medium text-white">
								{{ invoice.type.name }}
							</span>
						</div>

						<span
							v-if="invoice.type.code === 'tournament' && invoice.tournament"
							class="px-6 text-sm font-normal text-gray-400"
						>
							{{ invoice.tournament.name }}
						</span>

						<div class="px-6 flex gap-1.5 items-center">
							<span class="text-xl font-bold text-white">
								{{ formatAmount(invoice.balance) }}
							</span>

							<button
								v-if="invoice.type.code === 'demo'"
								type="button"
								class="p-1.5 ring-1 rounded-lg ring-gray-600 bg-gray-600/[0.32] hover:ring-1 ring-inset hover:ring-gray-600 hover:bg-gray-600/[0.32]"
								@click.stop.prevent="refreshBalance"
							>
								<BaseIcon
									name="base--fill--refresh"
									class="text-[16px] text-white"
									:class="{
										'animate-spin': isLoading,
									}"
								/>
							</button>

							<TariffsBadge
								v-if="invoice.type.code === 'real'"
								:type="user.accountType"
							/>
						</div>

						<div v-if="invoice.type.code === 'real'">
							<div class="flex flex-col px-6 gap-1 mt-3">
								<div class="flex flex-col items-start">
									<span class="text-sm font-normal text-gray-400">
										Реальные средства
									</span>

									<span class="text-sm font-bold text-gray-300">
										{{ formatAmount(invoice.realBalance) }}
									</span>
								</div>

								<div
									v-if="invoice.bonus"
									class="flex flex-col items-start"
								>
									<span class="text-sm font-normal text-gray-400">
										Бонусные средства
									</span>

									<span class="text-sm font-bold text-gray-300">
										{{ formatAmount(invoice.bonus) }}
									</span>
								</div>

								<div
									v-if="invoice.turnoverSum"
									class="flex flex-col items-start"
								>
									<span class="text-sm font-normal text-gray-400">
										Оборот для вывода бонуса
									</span>

									<span class="text-sm font-bold text-gray-300">
										{{ formatAmount(invoice.turnoverSum) }}
									</span>
								</div>
							</div>
						</div>
					</button>

					<hr
						v-if="idx !== invoices.length - 1"
						class="h-[1px] bg-gray-600 w-full border-0"
					>
				</template>
			</BaseDropdown>
		</BindPopover>
	</div>
</template>
