import type { EditEmailForm } from '../../core/types';
import { useApi } from '~/src/services/network';

export const useChangeEmail = () => {
	const api = useApi();

	return async (payload: EditEmailForm) =>
		await api('/Trader/update/', {
			method: 'post',
			body: payload,
		});
};
