import { useApi } from '@/src/services/network';
import { toDomain } from '@/src/features/profile/infrastructure/api/getTraderProfile';

export const useUploadAvatarTrader = () => {
	const api = useApi();

	return async (avatar: File) => {
		const formData = new FormData();
		formData.append('personal_photo', avatar as Blob);

		return (await api('/Trader/update/', {
			method: 'post',
			body: formData,
		})).mapRight(toDomain);
	};
};

export const useUploadAvatarPartner = () => {
	const api = useApi();

	return async (avatar: File) => {
		const formData = new FormData();
		formData.append('personal_photo', avatar as Blob);

		return (await api('/Partner/update/', {
			method: 'post',
			body: formData,
		})).mapRight(toDomain);
	};
};
