import { apiMethods } from '../../infrastructure/api';
import { saveTraderProfile } from '../../infrastructure/store';
import type { EditPersonalTraderForm } from '../../types';

export const useUpdateTraderPersonal = () => {
	const updateProfile = apiMethods.useUpdateTrader;

	return async (form: EditPersonalTraderForm) => {
		const result = await updateProfile(form);
		if (result.isRight()) {
			saveTraderProfile(result.value);
		}
		else {
			throw result.value;
		}
	};
};
