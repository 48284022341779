import { useUploadAvatarTrader, useUploadAvatarPartner } from './uploadAvatar';
import { useDeleteAvatarTrader, useDeleteAvatarPartner } from './deleteAvatar';
import { useUpdateLogin } from './updateLogin';
import { useChangePassword } from './changePassword';
import { useBlockAccount } from './blockAccount';
import { useChangeLocale } from './changeLocale';
import { useChangeEmail } from './changeEmail';
import { useGetTraderProfile } from './getTraderProfile';
import { useUpdateTrader } from './updateTraderProfile';
import { useAddTraderVerification } from './addTraderVerification';

export const apiMethods = {
	useUploadAvatarPartner,
	useUploadAvatarTrader,
	useDeleteAvatarTrader,
	useDeleteAvatarPartner,
	useUpdateLogin,
	useChangePassword,
	useBlockAccount,
	useChangeLocale,
	useChangeEmail,
	useGetTraderProfile,
	useUpdateTrader,
	useAddTraderVerification,
};
