import { apiMethods } from '../../infrastructure/api';
import { useIsUserPartner, useIsUserTrader } from '@/src/features/user';
import { saveUserPhoto } from '~/src/features/user/infrastructure/store';

export const useUploadAvatar = () => {
	const isTrader = useIsUserTrader();
	const isPartner = useIsUserPartner();

	const uploadAvatarTrader = apiMethods.useUploadAvatarTrader();
	const uploadAvatarPartner = apiMethods.useUploadAvatarPartner();

	return async (avatar: File) => {
		let result = null;
		if (isTrader) {
			result = await uploadAvatarTrader(avatar);
			if (result.isRight()) {
				saveUserPhoto(result.value.photo);
			}
			else {
				throw result.value;
			}
		}
		else if (isPartner) {
			result = await uploadAvatarPartner(avatar);
		}
	};
};
