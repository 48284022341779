import { useApi } from '@/src/services/network';
import { toDomainVerification} from "@/src/features/profile/infrastructure/api/getTraderProfile";

export const useAddTraderVerification = () => {
	const api = useApi();

	return async (files: File[]) => {
		const formData = new FormData();
		files.forEach((file, index) => {
			formData.append(`files[${index}]`, file);
		});

		return (await api('/Trader/addVerification/', {
			method: 'post',
			body: formData,
		})).mapRight(toDomainVerification);
	};
};
