import { useApi } from '@/src/services/network';
import { USER_ACCOUNT_TYPE, USER_ROLES } from '~/src/shared/lib';
import type {
	Response,
} from '~/src/shared/lib';

type UserDTO = any; // TODO
type TraderProfile = any; // TODO

export type TraderProfileDTO = Response<{
	user_verified: string;
	verification: {
		id: string;
		user: string;
		status: {
			id: string;
			name: string;
			code: string;
		};
		date_create: string;
		date_confirm: string;
		rejection_reason: string;
		files: string[];
	};
	phone_verified: '0' | '1';
	email_verified: '0' | '1';
	gender: string;
	birth_date: string;
	citizenship: string;
	city: string;
	address: string;
}>;

interface File {
	id: number;
	path: string;
}

interface Files {
	[key: number]: File;
}

export type VerificationDTO = ApiTypes.Response<{
	id: string;
	user: string;
	status: {
		id: string;
		name: string;
		code: string;
	};
	date_create: string;
	date_confirm: string;
	rejection_reason: string;
	files: Files;
}>;

export const toDomain = (user: UserDTO | TraderProfileDTO): TraderProfile => ({ //TODO UserDTO | TraderProfileDTO неверно
	id: user.result.id,
	login: user.result.login,
	email: user.result.email,
	firstName: user.result.name,
	lastName: user.result.last_name,
	photo: user.result.photo,
	phone: user.result.phone_number,
	colorCode: user.result.color || '1',
	accountType: user.result.tariff?.code ?? USER_ACCOUNT_TYPE.free,
	groupId: user.result.group?.[0]?.code ?? USER_ROLES.trader,
	language: user.result.language,
	registrationDate: user.result.date_register,
	refferalCode: user.result.referal_code,
	country: user.result.country,
	userVerified: user.result.user_verified,
	verification: user.result.verification
		? {
				id: user.result.verification.id,
				user: user.result.verification.id,
				status: {
					id: user.result.verification.status.id,
					name: user.result.verification.status.name,
					code: user.result.verification.status.code,
				},
				dateCreate: user.result.verification.date_create,
				dateConfirm: user.result.verification.date_confirm,
				rejectionReason: user.result.verification.rejection_reason,
				files: user.result.verification.files,
			}
		: null,
	phoneVerified: Boolean(+user.result.phone_verified),
	emailVerified: Boolean(+user.result.email_verified),
	gender: user.result.gender,
	birthDate: user.result.birth_date,
	citizenship: user.result.citizenship,
	city: user.result.city,
	address: user.result.address,
});

export interface TraderVerification {
	id: string;
	user: string;
	status: {
		id: string;
		name: string;
		code: string;
	};
	dateCreate: string;
	dateConfirm: string;
	rejectionReason: string;
	files: string[];
}

export const toDomainVerification = (verification: VerificationDTO): TraderVerification => {
	return {
		id: verification.result.id,
		user: verification.result.id,
		status: {
			id: verification.result.status.id,
			name: verification.result.status.name,
			code: verification.result.status.code,
		},
		dateCreate: verification.result.date_create,
		dateConfirm: verification.result.date_confirm,
		rejectionReason: verification.result.rejection_reason,
		files: Object.values(verification.result.files as Files).map((file: File) => file.path),
	};
};

export const useGetTraderProfile = () => {
	const api = useApi();

	return async () => (await api<UserDTO>('/api/Trader/getProfile/')).mapRight(toDomain);
};
