import { useApi } from '@/src/services/network';
import type { TraderProfile } from '@/src/features/user/types';
import type { EditPersonalTraderForm } from '@/src/features/profile/types';
import { toDomain } from '@/src/features/profile/infrastructure/api/getTraderProfile';
import { formatDate } from '~/src/shared/lib';

const fromDomain = (payload: EditPersonalTraderForm): any => {
	return {
		name: payload.firstName,
		last_name: payload.lastName,
		gender: payload.gender,
		birth_date: formatDate(payload.birthday[0], 'DD.MM.YYYY'),
		phone_number: payload.phone,
		country: payload.country,
		citizenship: payload.citizenship,
		city: payload.city,
		address: payload.address,
	};
};

export const useUpdateTrader: TraderProfile = async (payload: EditPersonalTraderForm) => {
	const result = await useApi()('/Trader/update/', {
		method: 'POST',
		body: fromDomain(payload),
	});
	return result.mapRight(toDomain);
};
